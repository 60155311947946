@font-face {
  font-family: 'DinBold';
  src: local('DinBold'), url(./fonts/din_alternate_bold-webfont.woff) format('woff'),url(./fonts/din_bold-webfont.woff2) format('woff2');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Myriad Pro';
  src: local('Myriad Pro'), url(./fonts/MyriadPro-Regular.woff) format('woff'),url(./fonts/MyriadPro-Regular.woff2) format('woff2');
}